<template>
<div class="d-flex flex-column">
    <div class="py-1" v-for="(promo, i) in promos">
        <promo :promo="promo" :index="i"></promo>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            promos: [],
        }
    },
    mounted() {
        this.fetchPromos();
    },
    methods: {
        fetchPromos() {
            axios.get('/promos').then(response => {
                if(response.data.success) {
                    this.promos = response.data.promos;
                }
            });
        },
    },
};
</script>
